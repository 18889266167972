<template>
  <div>
    <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item label="殡仪馆名称">
            <a-select
              v-decorator="['funeral_home_id']"
              placeholder="请选择殡仪馆"
              @focus="getFuneralHomeOption"
              allow-clear
            >
              <a-select-option
                v-for="funeralHome in funeralHomeOptions"
                :key="funeralHome.id"
                :value="funeralHome.id"
              >
                {{ funeralHome.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item label="厅房名称">
            <a-input
              v-decorator="['hall_name', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入厅房名称"
              allow-clear
              autocomplete="off"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findSystemFuneralHomeOptions } from '@/api/funeral_home'

export default {
  name: 'SearchHall',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'hall_search' }),
      funeralHomeOptions: []
    }
  },
  computed: {
    tenantId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    getFuneralHomeOption() {
      findSystemFuneralHomeOptions({ tenant_id: Number(this.tenantId) }).then(res => {
        if (res.code === 0) {
          this.funeralHomeOptions = res.data
        }
      })
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
