import request from '@/utils/request'

// 查询租户管理员
export function findTenantAdmin(params) {
  return request({
    url: `/tenants/admin`,
    method: 'get',
    params: params
  })
}

// 创建租户管理员
export function createTenantAdmin(data) {
  return request({
    url: `/tenants/admin`,
    method: 'post',
    data
  })
}

// 更新租户管理员
export function updateTenantAdmin(tenantId, data) {
  return request({
    url: `/tenants/admin/${tenantId}`,
    method: 'put',
    data
  })
}

// 更新用户有效性
export function updateTenantAdminEffective(id, data) {
  return request({
    url: `/tenants/admin/${id}/effective`,
    method: 'patch',
    data
  })
}

// 删除租户管理员
export function deleteTenantAdmin(id) {
  return request({
    url: `/tenants/admin/${id}`,
    method: 'delete'
  })
}

// 租户管理员详情
export function findTenant(id) {
  return request({
    url: `/tenants/admin/${id}`,
    method: 'get'
  })
}

// 租户选择框
export function findTenantAdminOptions() {
  return request({
    url: `/tenants/admin/options`,
    method: 'get'
  })
}

// 查询租户下殡仪馆
export function findTenantFuneralHomeList(id, params) {
  return request({
    url: `/tenants/${id}/funeral_home`,
    method: 'get',
    params: params
  })
}

// 查询租户下殡仪馆
export function findTenantHallList(id, params) {
  return request({
    url: `/tenants/${id}/hall`,
    method: 'get',
    params: params
  })
}

export function setTouchScreenPics(id, data) {
  return request({
    url: `/tenants/admin/${id}/set_touch_screen_pics`,
    method: 'put',
    data
  })
}
