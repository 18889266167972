import request from '@/utils/request'

// 列出所有殡仪馆
export function findFuneralHomes(params) {
  return request({
    url: `/funeral_homes`,
    method: 'get',
    params: params
  })
}
// 更新殡仪馆状态
export function updateFuneralHomeEffective(id, data) {
  return request({
    url: `/funeral_homes/${id}/effective`,
    method: 'patch',
    data
  })
}
// 更新殡仪馆信息
export function updateFuneralHome(id, data) {
  return request({
    url: `/funeral_homes/${id}`,
    method: 'put',
    data
  })
}
// 创建殡仪馆
export function createFuneralHome(data) {
  return request({
    url: `/funeral_homes`,
    method: 'post',
    data
  })
}

// 查询殡仪馆选项（通过当前用户租户id查询的）
export function findFuneralHomeOptions(params) {
  return request({
    url: `/funeral_homes/options`,
    method: 'get',
    params: params
  })
}

// 查询所有殡仪馆选项
export function findAllFuneralHomeOptions() {
  return request({
    url: `/funeral_homes/all/options`,
    method: 'get'
  })
}

// 查询殡仪馆选项（通过传tenant_id查询）
export function findSystemFuneralHomeOptions(params) {
  return request({
    url: `/funeral_homes/tenant/options`,
    method: 'get',
    params: params
  })
}

export function findFuneralHomeEffectiveOptions() {
  return request({
    url: `/funeral_homes/effective/options`,
    method: 'get'
  })
}

